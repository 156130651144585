import React, { useState, useRef } from 'react';
import { Modal, Form, Tooltip, Row, Col, Button, Input, Space, InputNumber, Select, message } from 'antd';
import { SearchOutlined, FileSyncOutlined } from '@ant-design/icons';
import { navigate } from 'gatsby';

import CustomerList from '../customer_list';
import UploadFile from '../upload_files';
import { StsUploadFilesList } from '../upload_files/sts_upload_files_list';
import OverallLoading from '../overall_loading';
import SelectSalePlatform from '../select_sale_platform';
import { CreateRecordRequestPost } from './create_records_request';

const { Option } = Select;

const formInitObj = {
  customername: '',
  diopter_r: '',
  diopter_l: '',
  pupillary_distance: '',
  product_title: '',
  products_quantity: 1,
  purchase_amount: 0,
  sale_platform: 0,
  order_state: 0,
  receive_address: '',
  explains: "",
  upload: []
};

// 创建购买记录组件

const formItemLayout = {
  layout: 'horizontal',
  labelCol: {
    xs: { span: 12, },
    sm: { span: 10, },
  },
  wrapperCol: {
    xs: { span: 12, },
    sm: { span: 14, },
  },
};
const tailFormItemLayout = {
  wrapperCol: {
    xs: { span: 24, offset: 0, },
    sm: { span: 16, offset: 8, },
  },
};

const normFile = e => {
  console.log('Upload event:', e);
  if (Array.isArray(e)) {
    return e;
  }
  return e && e.fileList;
};

const CreatePurchaseRecords = () => {
  // 创建购买记录组件
  const [cleanImagesCount, setCleanImagesCount] = useState(0);

  const [spinning, setSpinning] = useState(false);
  const [form] = Form.useForm();
  const [showSelectCustomerModal, setShowSelectCustomerModal] = useState(false);
  const selectedCustomerObj = useRef({});

  const [requestCount, setRequestCount] = useState(0);
  const reqObject = useRef({});
  const uploadedImagesObj = useRef({});

  const responseDataHandle = (respObj) => {
    // respObj 是请求结果
    console.log(respObj);
    setSpinning(false);
    if (respObj.code === 1000) {
      // 请求成功
      form.setFieldsValue({ ...formInitObj });
      setCleanImagesCount(cleanImagesCount + 1);
      // message.success('Success!');
      message.success('创建订单成功!');
    } else {
      // message.error('Network Error...');
      message.error('创建失败...');
    }
  }

  const selectCustomerHandle = (customerObj) => {
    // 选中顾客后操作
    selectedCustomerObj.current = customerObj;
    const objKeys = Object.keys(customerObj);
    const nickName =
      objKeys.includes('name')
        ? customerObj.name
        : objKeys.includes('wx_nickname') ? customerObj.wx_nickname : '';
    form.setFieldsValue({
      customername: nickName,
    });
    setShowSelectCustomerModal(false);
  }

  const SelectOrderState = () => {
    // 0:订单交易完成, 1:定制中, 2:已发货
    return (
      <Select onChange={(val) => form.setFieldsValue({ order_state: Number(val) })} defaultValue="0">
        <Option value="0">订单交易完成</Option>
        <Option value="1">定制中</Option>
        <Option value="2">已发货</Option>
      </Select>
    )
  }

  const onFinish = values => {
    // 提交表单
    const deepcopyObj = { ...values, ...uploadedImagesObj.current };
    delete deepcopyObj.customername;
    const customerObj = { ...selectedCustomerObj.current };
    // 判断是顾客表还是顾客微信表，获取顾客id
    deepcopyObj.customer_id = Object.keys(customerObj).includes('customer_id')
      ? customerObj.customer_id
      : customerObj.id ? customerObj.id : 0;
    // const imgLists = [].concat(deepcopyObj.upload);
    // delete deepcopyObj.upload;
    // // console.log(imgLists);
    // for (let index = 0; index < imgLists.length; index++) {
    //   const resp = imgLists[index].response;
    //   let imgKey = 'image_cos_name' + (index + 1);
    //   deepcopyObj[imgKey] = resp.image_cos_name;
    // }
    // 打印上传对象
    console.log(deepcopyObj);
    reqObject.current = deepcopyObj;
    setSpinning(true);
    setRequestCount(requestCount + 1);
  };

  return (
    <div>
      <OverallLoading spinning={spinning} />
      <h3>新建销售记录</h3>

      <StsUploadFilesList
        onChange={(uploadedImageObj) => { uploadedImagesObj.current = uploadedImageObj; }}
        cleanImagesCount={cleanImagesCount}></StsUploadFilesList>

      <Form
        {...formItemLayout}
        form={form}
        name="createrecord"
        onFinish={onFinish}
        initialValues={{ ...formInitObj }}
        scrollToFirstError
      >
        <Form.Item label="顾客" extra="请选择或新建顾客">
          <Row gutter={8}>
            <Col span={18}>
              <Form.Item
                name="customername"
                noStyle
              >
                <Input disabled={true} />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Tooltip title="选择或新建顾客">
                <Button type="primary" onClick={() => { setShowSelectCustomerModal(true) }} shape="circle" icon={<SearchOutlined />} />
              </Tooltip>
            </Col>
          </Row>
        </Form.Item>

        <Form.Item label="光度 R" name="diopter_r" extra="选择或输入光度R">
          <Row gutter={8}>
            <Col span={18}>
              <Form.Item name="diopter_r" noStyle><Input /></Form.Item>
            </Col>
            <Col span={6}>
              <Tooltip title="选择光度">
                <Button type="primary" shape="circle" icon={<FileSyncOutlined />} />
              </Tooltip>
            </Col>
          </Row>
        </Form.Item>

        <Form.Item label="光度 L" extra="选择或输入光度L">
          <Row gutter={8}>
            <Col span={18}>
              <Form.Item name="diopter_l" noStyle><Input /></Form.Item>
            </Col>
            <Col span={6}>
              <Tooltip title="选择光度">
                <Button type="primary" shape="circle" icon={<FileSyncOutlined />} />
              </Tooltip>
            </Col>
          </Row>
        </Form.Item>

        <Form.Item label="瞳距PD" name="pupillary_distance" ><Input /></Form.Item>
        <Form.Item label="商品名称" name="product_title" ><Input /></Form.Item>
        <Form.Item label="商品数量" name="products_quantity">
          <InputNumber min={0} max={1000}
            formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
            parser={value => value.replace(/\s?|(,*)/g, '')} />
        </Form.Item>
        <Form.Item label="销售金额" name="purchase_amount">
          <InputNumber min={0} max={1000000}
            formatter={value => `¥ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
            parser={value => value.replace(/¥\s?|(,*)/g, '')} />
        </Form.Item>
        <Form.Item label="销售平台" name="sale_platform">
          <SelectSalePlatform />
        </Form.Item>
        <Form.Item label="订单状态" name="order_state"><SelectOrderState /></Form.Item>
        <Form.Item label="收货地址" name="receive_address" ><Input /></Form.Item>
        <Form.Item label="说明" name="explains" ><Input /></Form.Item>

        {/* <Form.Item
          name="upload"
          label="图片"
          valuePropName="fileList"
          getValueFromEvent={normFile}
          extra="选择或拍摄图片"
        ><UploadFile fileList={fileList} /></Form.Item> */}

        <Form.Item {...tailFormItemLayout}>
          <Space size="large">
            <Button type="primary" htmlType="submit">提交</Button>
            <Button type="dashed" onClick={() => { navigate('/'); }}>取消</Button>
          </Space>
        </Form.Item>
      </Form>

      <CreateRecordRequestPost requestCount={requestCount}
        reqObject={reqObject.current}
        responseDataHandle={responseDataHandle}
      ></CreateRecordRequestPost>

      <Modal
        visible={showSelectCustomerModal}
        title="选择顾客"
        footer={[
          <Button key="back" onClick={() => { setShowSelectCustomerModal(false); }}>取消</Button>
        ]}
      ><CustomerList
          selectCustomerHandle={selectCustomerHandle}
          clickCreatedCustomerHandle={selectCustomerHandle} /></Modal>
    </div>
  )
}

export default CreatePurchaseRecords;
