import React from 'react';
import AxiosRequest from '../apis';

const CreateRecordRequestPost = ({ requestCount, reqObject, responseDataHandle }) => {
  // 创建订单 request
  const api = 'records-admin/v1/purchaserecord';
  const method = 'post';
  const reqParams = {};

  return (
    <AxiosRequest
      responseDataHandle={responseDataHandle} api={api} method={method}
      reqParams={reqParams} reqObject={reqObject} requestCount={requestCount}
    ></AxiosRequest>
  );
}

export { CreateRecordRequestPost };
