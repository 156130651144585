import React, { useState, useEffect } from 'react';
import { Upload, Button } from 'antd';
import { UploadOutlined } from '@ant-design/icons';

import customConfig from '../../custom_config';

import { getToken } from '../../assets/common_funcs/token_handle';

const UploadFile = ({ onChange, defaultFileList, fileList, multiple, maxFiles }) => {
  // 上传文件组件
  const [showUploadButton, setShowUploadButton] = useState(true);
  useEffect(() => {
    if (fileList.length >= maxFiles) {
      setShowUploadButton(false);
    } else {
      setShowUploadButton(true);
    }
  }, [fileList, maxFiles])

  const uploadUrl = 'files-handle/v1/uploadfiles';

  const uploadFileProps = {
    action: `${customConfig.apiBaseUrl}/${uploadUrl}`,
    listType: 'picture',
    defaultFileList: defaultFileList,
    fileList: fileList,
    onChange: onChange,
    multiple: multiple,
    headers: {
      Authorization: getToken()
    }
  };

  return (
    <div>
      <Upload {...uploadFileProps}>
        {showUploadButton ? (<Button><UploadOutlined /> Upload</Button>) : null}
      </Upload>
    </div>
  )
};

UploadFile.defaultProps = {
  multiple: true,
  defaultFileList: [],
  fileList: [],
  maxFiles: 5,
  onChange: (info) => { console.log(info.file) },
};

export default UploadFile;
