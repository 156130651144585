import React from 'react';

import SEO from "../components/seo";
import SiteLayout from '../components/site_layout';
import CreatePurchaseRecords from '../components/create_records';

const CreateRecordsPage = () => (
  <SiteLayout>
    <SEO title='CreateRecord'></SEO>
    <CreatePurchaseRecords></CreatePurchaseRecords>
  </SiteLayout>
);

export default CreateRecordsPage;
